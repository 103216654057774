var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('searchBody',[_c('search',{staticClass:"searchbar",attrs:{"label-width":80,"searchs":_vm.searchs},on:{"search":_vm.onSearch,"reset":_vm.reset}}),_c('Card',{ref:"table_container",staticClass:"table_container"},[_c('Table',{staticClass:"table mt_10",attrs:{"stripe":"","columns":_vm.dataColumn,"data":_vm.dataList},scopedSlots:_vm._u([{key:"goodMessage",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex align_center justify_center item"},[_c('div',[_c('previewImage',{attrs:{"thumWidth":60,"thumHeight":60,"src":row.goodPic}})],1),_c('div',{staticClass:"flex column align_start justify_center ml_10"},[_c('div',{staticClass:"break_all"},[_vm._v(_vm._s(row.goodName))]),_c('div',{staticClass:"break_all"},[_vm._v(_vm._s(row.goodSpec))])])])]}},{key:"classType",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.getClassTypeName(row.classList)))]}},{key:"tuiType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s({ 1: '仅退款', 2: '退货退款' }[row.tuiType])+" ")]}},{key:"tuiPrice",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("cny")(row.tuiPrice)))]}}])})],1),_c('div',{staticClass:"relative width_100_percent text_align_right"},[_c('Page',{attrs:{"size":"small","total":_vm.totalPageNum || 1,"page-size":1,"show-elevator":""},on:{"on-change":_vm.currentChange}})],1),_c('mySpin',{attrs:{"loading":_vm.ajaxLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }