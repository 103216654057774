<template>
    <searchBody>
        <search class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="goodMessage">
                    <div class="flex align_center justify_center item">
                        <div>
                            <previewImage :thumWidth="60" :thumHeight="60" :src="row.goodPic"></previewImage>
                        </div>
                        <div class="flex column align_start justify_center ml_10">
                            <div class="break_all">{{ row.goodName }}</div>
                            <div class="break_all">{{ row.goodSpec }}</div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row }" slot="classType">{{ getClassTypeName(row.classList) }}</template>
                <template slot-scope="{ row }" slot="tuiType">
                    {{ { 1: '仅退款', 2: '退货退款' }[row.tuiType] }}
                </template>
                <template slot-scope="{ row }" slot="tuiPrice">{{ row.tuiPrice | cny }}</template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { reqOrderRefundGood } from '@/lib/request/auth2';

export default {
    name: 'accountList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        return {
            searchs: [
                { id: hm.createId(), label: '关键词', placeholder: '输入商品名称', type: 'input', bind: 'goodName' },
                {
                    id: hm.createId(),
                    label: '退款原因',
                    placeholder: '请选择',
                    type: 'select',
                    bind: 'cause',
                    list: [
                        { id: '1', title: '错拍' },
                        { id: '2', title: '误拍' },
                    ],
                },
            ],
            dataColumn: [
                { slot: 'goodMessage', title: '商品描述', minWidth: 250 },
                { slot: 'classType', title: '分类', minWidth: 170 },
                { slot: 'tuiType', title: '退款类型', minWidth: 120 },
                { key: 'tuiCause', title: '退款原因', minWidth: 120 },
                { key: 'address', title: '地址', minWidth: 150 },
                { slot: 'tuiPrice', title: '退款金额', minWidth: 120 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getClassTypeName(classList = {}) {
            return classList.title + '-' + (classList.children || {}).title;
        },
        //获取列表数据
        getList() {
            console.log(JSON.stringify(this.params, null, '\t'));

            this.showAjaxLoading();
            reqOrderRefundGood(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
    },
};
</script>
